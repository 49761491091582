<template>
  <b-container v-if="!isLoading" class="col">
    <b-card class="m-5">
      <b-card-body>
        <b-col>
          <b-row>
            <b-col cols="3" class="d-flex justify-content-center">
              <img
                :src="
                  driver.driver && driver.driver.driver_image
                    ? srcUrl + driver.driver.driver_image
                    : ''
                "
                class="img-fluid driver-img"
              />
            </b-col>
            <b-col cols="9" class="py-5">
              <h3>
                <b>{{ driver.id }} - {{ driver.name }}</b>
              </h3>
              <h5>
                <b>Outlet</b>
              </h5>
              <h5>
                <b class="font-large-1 text-light-purple"
                  >F {{ driver.driver_branch.area }}</b
                >
              </h5>
            </b-col>
          </b-row>
        </b-col>
        <div>
          <hr />
        </div>
        <div class="m-5">
          <b-row class="mb-5">
            <b-col cols="3">
              <h5>
                <b> Date Created </b>
              </h5>
            </b-col>
            <b-col cols="9">
              <h5>
                {{ driver.driver.registration_date }}
              </h5>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col cols="3">
              <h5>
                <b> Address </b>
              </h5>
            </b-col>
            <b-col cols="9">
              <h5>
                {{ driver.driver.unit_no }} {{ driver.driver.address_1 }}
                {{ driver.driver.address_2 }}, {{ driver.driver.area }},
                {{ driver.driver.state }}, {{ driver.driver.country }}
              </h5>
            </b-col>
          </b-row>
          <b-row class="mb-5">
            <b-col cols="3">
              <h5>
                <b> Phone </b>
              </h5>
            </b-col>
            <b-col cols="9">
              <h5>{{ driver.phone }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <h5>
                <b> Email Address </b>
              </h5>
            </b-col>
            <b-col cols="9">
              <h5>{{ driver.email }}</h5>
            </b-col>
          </b-row>
        </div>
        <div class="d-flex justify-content-center align-items-center my-2">
          <b-button
            variant="gradient-primary"
            class="w-40 box-shadow-1"
            to="/phone-book"
          >
            Back to Phonebook
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      isLoading: true,
      srcUrl: `${this.$s3URL}/minidc/driver_photo/`,
      driver: {},
    };
  },

  created() {
    this.$http.get(`driver/${this.$route.params.id}`).then((response) => {
      console.log(response);
      if (response.data.status) {
        this.driver = response.data.data;
      } else {
        this.$swal(requestError);
      }
      this.isLoading = false;
    });
  },

  methods: {},
};
</script>
<style scoped>
.driver-img {
  height: 220px;
  width: 220px;
  border-radius: 50%;
}
</style>
